<template>
  <div>
    <!-- <b-tabs v-model="tab">
      <b-tab
        v-for="mediaTab in tabs"
        :key="mediaTab.meta.name"
        :name="mediaTab.meta.path"
        :title="mediaTab.meta.label"
      />
    </b-tabs> -->
  </div>
</template>

<script>
export default {
  name: "MediaTabs",
  data() {
    return {
      tab: 0,
      tabs: [],
    }
  },
  created() {
    this.tabs = []
  },

  beforeDestroy() {
    this.tabs = []
  },

  mounted() {
    const tabs = this.$store.state.contentMetaData.xJSON.pages
    this.tabs = tabs.filter((el) => el.meta.name == "video")
    this.tab = 0
  },
}
</script>
